import ArticleOverviewTemplate from '../../components/templates/ArticleOverviewPage';
import Meta from '../../components/Meta.js';
import PropTypes from 'prop-types';
import React from 'react';
import withRoot from '../../hoc/withRoot';
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import { MarkdownHtml } from '../../components/organisms/Markdown';
import { getProps as footerProps } from '../utils/mapper/footer';
import { graphql } from 'gatsby';
import { mapToProps as mapToChildrenContentTeaserProps } from '../utils/mapper/childrenContentTeasers';
import { mapToProps as mapToIntroProps } from '../utils/mapper/intro';
import { mapToProps as mapToMarkdownProps } from '../utils/mapper/markdown';
import { mapToProps as mapToMetaProps } from '../utils/mapper/meta';
import { mapToProps as mapToTopbarProps } from '../utils/mapper/topbar';
import { siteMetadata } from '../utils/helper/data';

const LabPage = props => {
  const { location, data } = props;
  const smData = siteMetadata(data);

  return (
    <>
      <Meta {...mapToMetaProps(data, location)} />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: smData.title,
            item: smData.siteUrl
          },
          {
            position: 2,
            name: 'Laboratory',
            item: `${smData.siteUrl}/laboratory`
          }
        ]}
      />
      <ArticleOverviewTemplate
        teasersData={mapToChildrenContentTeaserProps(data)}
        markdownComponent={MarkdownHtml}
        MarkdownProps={mapToMarkdownProps(data)}
        TopBarProps={mapToTopbarProps(data, location)}
        IntroProps={mapToIntroProps(data)}
        FooterProps={footerProps()}
      />
    </>
  );
};

LabPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default withRoot(LabPage);

export const pageQuery = graphql`
  query LabOverviewTemplate($childrenTemplateKey: String) {
    site {
      siteMetadata {
        title
        siteUrl
        ...MetaSiteMetadata
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "labOverviewPage" } }) {
      frontmatter {
        title
        description
        ...Intro
        ...MetaFrontMatter
      }
      ...MarkdownContent
    }
    ...TopBar
    ...ChildrenContentTeasers
  }
`;
